<template>
  <div class='demo-app'>
    <div class='demo-app-top'>
      <!--<button @click="toggleWeekends">toggle weekends</button>
      <button @click="gotoPast">go to a date in the past</button>
      (also, click a date/time to add an event) -->
    </div>

    <modal :show.sync="showResultsModal" modal-classes="modal-secondary" size="lg">
      <div class="custom-header">
        <h3 v-if="!currentId">{{ $t("EVENTS.ADD_EVENT") }}</h3>
        <h3 v-if="currentId">{{ $t("EVENTS.EDIT_EVENTS") }}</h3>
      </div>
      <form @submit.prevent="addEvent">
        <label>{{ $t("EVENTS.TITLE") }} </label>
        <input v-model="eventTitle" required>
        <br>
        <label>{{ $t("EVENTS.DATE_START") }}</label>
        <flat-picker :config="{
          allowInput: true,
          locale: $flatPickrLocale(),
          enableTime: true,
          dateFormat: 'Y-m-d H:i',
          minDate: 'today',

        }" class="form-control datepicker" v-model="eventStart">
        </flat-picker>
        <br>
        <label>{{ $t("EVENTS.DATE_END") }} </label>
        <flat-picker :config="{
          allowInput: true,
          locale: $flatPickrLocale(),
          enableTime: true,
          dateFormat: 'Y-m-d H:i',
          minDate: 'today',
        }" class="form-control datepicker" v-model="eventEnd">
        </flat-picker>
        <br>
        <div>
          <label>{{ $t("EVENTS.RECURENT") }} </label>
          <input :disabled="isDisabled" type="checkbox" name="is_recurrent" id="is_recurrent" v-model="isRecurrent">
        </div>
        <div v-if="isRecurrent">
          <br>
          <h3>{{ $t("EVENTS.OCURENCE") }}</h3>
          <!-- <label>Date de début: </label>
          <input type="datetime-local" v-model="recurentEventStart" required> -->
          <label>{{ $t("EVENTS.DATE_END") }}</label>
          <flat-picker :config="{
            allowInput: true,
            locale: $flatPickrLocale(),
            enableTime: true,
            dateFormat: 'Y-m-d H:i',
            minDate: 'today',
          }" class="form-control datepicker" v-model="recurentEventEnd">
          </flat-picker>
          <label>{{ $t("EVENTS.FREQUENCE") }}: </label>
          <select v-model="eventRepeted">
            <option disabled value="">{{ $t("EVENTS.SELECT") }}</option>
            <option value="day">{{ $t("EVENTS.ALL_DAYS") }}</option>
            <option value="week">{{ $t("EVENTS.ALL_WEEKS") }}</option>
            <option value="month">{{ $t("EVENTS.ALL_MONTH") }}</option>
          </select>
        </div>
        <div>
          <label>{{ $t("EVENTS.COLOR") }}:</label>
          <input type="color" v-model="color" />
        </div>
        <div>

          <div class="form-wrapper">
            <base-input :label="`${$t('COMMON.AUCTION')}(*)`" :placeholder="$t('COMMON.AUCTION')">
              <auction-selector :placeholder="$t('COMMON.AUCTION')" :filterable="true" :showAll="false" @auctionChanged="(auctionId) => {
                selectedAuction = auctionId;
              }
            " />
            </base-input>
          </div>
        </div>
        <tr>
          <td>
            <div>
              <button v-on:click='onAdd' v-if="!currentId">{{ $t("EVENTS.ADD") }}</button>
              <button v-on:click='onAdd' v-if="currentId">{{ $t("EVENTS.EDIT") }}</button>
            </div>
          </td>
        </tr>
        <br>
      </form>

    </modal>

    <modal name="show-modal" :show.sync="showVModal" modal-classes="modal-secondary" size="lg">
      <tr>

        <td>
          <div>
            <button @click='editEventModal()'>{{ $t("EVENTS.EDIT") }}</button>
          </div>
        </td>
        <td>
          <div>
            <button @click="deleteEvent()">{{ $t("EVENTS.DELETE_THIS_EVENT") }}</button>
            <button @click="deleteAllEvents()" v-if="isRecurrent">{{ $t("EVENTS.DELETE_ALL_EVENT") }}</button>
          </div>
        </td>
      </tr><br>
      {{ $t("EVENTS.TITLE") }} : {{ eventTitle }}
      <br>
      {{ $t("EVENTS.DATE_START") }} : {{ eventStart }}
      <br>
      {{ $t("EVENTS.DATE_END") }} : {{ eventEnd }}

    </modal>


    <FullCalendar class='demo-app-calendar' ref="fullCalendar" defaultView="dayGridMonth" :header="{
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    }" :plugins="calendarPlugins" :weekends="calendarWeekends" :events="this.events" @dateClick="dateClick" @select=""
      :eventsSet="handleEvents" @eventClick="showModal" />
  </div>

</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { cloneDeep } from "lodash";
import defaultEvent from "./defaultEvent";
import Custom from "./Custom";
import {
  addDays,
  subDays,
  isBefore,
  isAfter,
  eachDay,
  format,
  addMonths,
  getYear,
  setYear,
  isWithinRange
} from "date-fns";
import swal from "sweetalert2";
import { ref } from 'vue'
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import AuctionSelector from "../../../../components/AuctionSelector.vue";

const picked = ref('one')
export default {
  components: {
    FullCalendar,
    flatPicker,
    AuctionSelector,

    // make the <FullCalendar> tag available
  },
  data: function () {
    return {
      calendarPlugins: [ // plugins must be defined in the JS
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin // needed for dateClick
      ],
      calendarWeekends: true,
      selectable: true,
      events: [],
      selectedDate: "",

      total: 0,
      pagination: {
        perPage: 500,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      loading: true,
      query: null,
      selectedRows: [],
      eventTitle: '',
      eventStart: '',
      eventEnd: '',
      eventRepeted: 'none',
      showResultsModal: false,
      showVModal: false,
      calendar: '',
      currentId: null,
      excludedDates: new Date('2024/07/09'),
      picked: 'one',
      color: 'black',
      isRecurrent: false,
      recurentEventStart: '',
      recurentEventEnd: '',
      parentId: null,
      isEdit: false,
      isDisabled: false,
      auctions: [],
      selectedAuction: null,
    }
  },
  computed: {
  },
  methods: {
    async deleteAllEvents(Event) {
      console.log(Event)
      const confirmation = await swal.fire({
        title: this.$t("EVENTS.DELETE_THIS_EVENT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("events/deleteAll", this.currentId);
          this.showVModal = false;

          this.$notify({
            type: "success",
            message: this.$t("EVENTS.EVENT_DELETED"),
          });
          this.events = this.events.filter(event => event.id !== this.currentId);
          this.getList();

        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async deleteEvent(Event) {
      console.log(Event)
      const confirmation = await swal.fire({
        title: this.$t("EVENTS.DELETE_THIS_EVENT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("events/destroy", this.currentId);
          this.showVModal = false;

          this.$notify({
            type: "success",
            message: this.$t("EVENTS.EVENT_DELETED"),
          });
          this.events = this.events.filter(event => event.id !== this.currentId);


        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async onAdd(arg) {

      let eventData = {
        name: this.eventTitle,
        start_at: this.eventStart,
        end_at: this.eventEnd,
        recurrent: this.isRecurrent,
        frequency: this.eventRepeted,
        color: this.color,
        recurent_start_at: this.recurentEventStart,
        recurent_end_at: this.recurentEventEnd,
        type: "events",
        auction_id: this.selectedAuction,
      }

      if (!this.eventTitle || !this.eventStart || !this.eventEnd) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        return;
      }
      if (!(this.eventStart < this.eventEnd)) {
        this.$notify({
          type: "danger",
          message: this.$t("EVENTS.DATE_INVALIDE"),
        });

        return;
      }

      if ((this.eventRepeted !== 'none') && (!this.recurentEventEnd)) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.showResultsModal = false;
        return;
      }

      if (this.currentId) {
        const confirmation = await swal.fire({
          title: this.$t("EVENTS.EDIT_EVENTS"),
          type: "question",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: this.$t("COMMON.YES"),
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        if (this.picked === 'one') {

          try {
            await this.$store.dispatch("events/update", { ...eventData, id: this.currentId });
            this.$notify({
              type: "success",
              message: this.$t("EVENTS.EVENTS_UPDATED"),
            });
            this.showResultsModal = false;

            this.getList();
            const obj = JSON.parse(event.target.response);

            this.$emit("onViewEvent", event, true);
            this.loading = false;
          } catch (error) {
            console.log(error)
            this.$notify({
              type: "danger",
              message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
            });
          }
          return;
        }
        try {
          if (confirmation.value === true && this.isRecurrent === 1 && this.picked === 'all') {
            await this.$store.dispatch("events/deleteAll", this.currentId);
          }
        } catch (error) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
        }
      }

      if (this.picked !== 'one' || (this.currentId === null && this.picked === 'one')) {
        try {
          await this.$store.dispatch("events/add", eventData);
          this.$notify({
            type: "success",
            message: this.$t("EVENTS.EVENTS_ADDED"),
          });
          this.eventRepeted = 'none';
          this.eventTitle = "";
          this.eventStart = "";
          this.eventEnd = "";
          this.isRecurrent = false;
          this.getList();

          const obj = JSON.parse(event.target.response);

          this.$emit("onViewEvent", event, true);
          this.loading = false;
        } catch (error) {
          console.log(error)
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });

        }
      }


      this.showResultsModal = false;

    },
    showModal(arg) {
      console.log("show", arg.event)

      this.eventTitle = arg.event._def.title;
      //this.eventStart = arg.event.start;
      this.eventStart = (arg.event.start);

      this.eventEnd = (arg.event.end) ? (arg.event.end) : (arg.event._instance.range.end);

      this.currentId = arg.event._def.publicId;

      this.recurentEventStart = arg.event._def.extendedProps.sartRec;
      this.recurentEventEnd = arg.event._def.extendedProps.endRecu;

      console.log("id", this.currentId)
      console.log("start", this.eventStart)
      console.log("end", this.eventEnd)
      this.showVModal = true;

      this.isRecurrent = arg.event._def.extendedProps.isRecu;
      this.eventRepeted = arg.event._def.extendedProps.freq
      this.color = arg.event.backgroundColor;
      console.log("isRecurrent", this.isRecurrent)

    },
    toggleWeekends() {
      this.calendarWeekends = !this.calendarWeekends
    },
    gotoPast() {
      let calendarApi = this.$refs.fullCalendar.getApi() // from the ref="..."
      calendarApi.gotoDate('2000-01-01') // call a method on the Calendar object
    },
    addEvent(event) {
    },
    getDaysOfWeek(startDate, endDate) {
      const daysOfWeek = [];
      const currentDate = new Date(startDate);

      while (currentDate < new Date(endDate)) {
        console.log("ssss")

        const day = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
        if (!daysOfWeek.includes(day)) {
          daysOfWeek.push(day);
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }

      // Sort the days for better readability (optional)
      daysOfWeek.sort((a, b) => a - b);

      return daysOfWeek;
    },
    async editEventModal() {
      const htmlContent = `
        <div ${this.isRecurrent && this.currentId ? '' : 'style="display:none;"'}>
          <input v-model="picked" type="radio" id="one" value="one" name="picked" ${this.picked === 'one' ? 'checked' : ''}>
          <label for="one">${this.$t("EVENTS.THIS_EVENTS")}</label>
          <input v-model="picked" type="radio" id="all" value="all" name="picked" ${this.picked === 'all' ? 'checked' : ''}>
          <label for="all">${this.$t("EVENTS.ALL_EVENTS")}</label>
        </div>
      `;

      const confirmation = await swal.fire({
        title: this.$t("EVENTS.EDIT_EVENTS"),
        icon: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-warning"
        },
        html: htmlContent,
        onOpen: () => {
          const oneRadio = document.getElementById('one');
          const allRadio = document.getElementById('all');
          oneRadio.addEventListener('change', () => {
            this.picked = 'one';
            console.log(this.picked)
          });
          allRadio.addEventListener('change', () => {
            this.picked = 'all';
            console.log(this.picked)
          });
        }
      });
      if (confirmation.isConfirmed) {
        console.log('Picked:', this.picked);
      } else {
        return;
      }
      if (this.currentId) {
        this.isDisabled = true;
        // Update event
        const updatedEvent = {
          title: this.eventTitle,
          start: this.eventStart,
          end: this.eventEnd,
          recurrent: this.eventRepeted,
        };


      }
      this.showResultsModal = true;
      this.showVModal = false;

    },
    async dateClick(selectInfo) {
      this.isDisabled = false;

      let dateToday = new Date();
      if ((new Date(selectInfo.dateStr)) < (dateToday.setDate(dateToday.getDate() - 1))) {

        const confirmation = await swal.fire({
          title: this.$t("EVENTS.NOT_SELECTED"),
          icon: 'error',

        });

        return;
      }
      this.calendar = selectInfo.view.context.calendar;
      let nextDate = new Date(selectInfo.dateStr)
      const formattedDate = nextDate.toISOString().slice(0, 16);

      this.eventStart = formattedDate;
      this.eventEnd = formattedDate;
      this.showResultsModal = true;
      console.log("ev", formattedDate)

      this.currentId = null;
      this.eventRepeted = 'none';
      this.eventTitle = "";
      this.isRecurrent = false;
    }
    ,
    handleEvents(events) {
      this.events = events
    },
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),
    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { name: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
        };


        await this.$store.dispatch("events/list", params);
        this.events = this.$store.getters["events/list"];
        this.total = this.$store.getters["events/listTotal"];
        this.events = this.events.filter(event => (event.parent_id || event.recurrent == 0)).map(event => {

          let currentDate = new Date(event.start_at)
          let nextDate = new Date(currentDate)
          nextDate.setDate(currentDate.getDate() + 1)


          let date = new Date(event.start_at);
          const start = new Date(event.start_at);
          const end = new Date(event.end_at);
          this.picked = "one";
          this.currentId = null;
          this.isRecurrent = false;

          console.log('event.name', event.name)
          console.log('end', event.end_at)
          return {
            title: event.name,
            start: event.start_at,
            end: event.end_at,
            color: event.color,
            id: event.id,
            sartRec: event.recurent_start_at,
            endRecu: event.recurent_end_at,
            isRecu: event.recurrent,
            freq: event.frequency
          }
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async fetchAuctions() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { name: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
        };
        await this.$store.dispatch("auctions/list", params);
        this.auctions = await this.$store.getters["auctions/list"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.getList();
    this.fetchAuctions();
  },
  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterReseller: {
      handler: "getListDebounced",
      immediate: true,
    },
    // selectedReseller: {
    //   handler: "getListDebounced",
    //   immediate: true,
    // },
  },
}

</script>

<style lang='scss'>
// you must include each plugins' css
// paths prefixed with ~ signify node_modules
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';

.modal-secondary .modal-content {
  background-color: #dce2e5;
  color: #212529;
}

/*.demo-app {
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-top {
  margin: 0 0 3em;
}

.demo-app-calendar {
  margin: 0 auto;
  max-width: 900px;
}
*/
</style>